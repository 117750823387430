
        <nav class="navbar navbar-expand-md bg-dark navbar-dark">
            <!-- Brand -->
            <a class="navbar-brand" style="color: white;" (click)="Redirige('inicio')"><img src="/assets/icons/icon-72x72.png" alt="" style="height: 5vh;"></a>
        
            <!-- Toggler/collapsibe Button -->
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                <span class="navbar-toggler-icon"></span>
            </button>
        
            <!-- Navbar links -->
            <div class="collapse navbar-collapse ml-5" id="collapsibleNavbar">
                <ul class="navbar-nav ml-5" style="color: #DFF2F0;">
                    <li class="nav-item dropdown">
                        <a (click)="Redirige('inicio')" style="color: white;"><i class="fas fa-home"></i>&nbsp;Inicio</a>
                    </li>
                    <li class="nav-item ml-3">
                        <a (click)="Redirige('nuevoticket')" style="color: white;"><i class="fas fa-plus"></i>&nbsp;Nuevo ticket</a>
                    </li>
                    <li class="nav-item ml-3">
                        <a (click)="Redirige('ticketsactivos')" style="color: white;"><i class="far fa-edit"></i>&nbsp;Tickets activos</a>
                    </li>
                    <li class="nav-item ml-3">
                        <a (click)="Redirige('ticketsfinalizados')" style="color: white;"><i class="fas fa-check"></i>&nbsp;Tickets finalizados</a>
                    </li>
                    <li class="nav-item ml-3">
                        <a (click)="openAcercaDe(mymodal5)" style="color: white;"><i class="fas fa-info-circle"></i>&nbsp;Acerca de</a>
                    </li>
                    <li class="nav-item ml-3" >
                        <a (click)="LogOut()" style="color: white;"><i class="fas fa-sign-out-alt" ></i>&nbsp;Salir</a>
                    </li>
                    
                </ul>
            </div>
        </nav>
        <div class="container-fluid pt-3" id="contenido">
            <h3>Nuevo ticket</h3>
            <div class="row">
                <div class="col-sm-10">
                    <label for="">*Módulo, servicio o reporte</label>
                    <select class="form-control">
                        <option *ngFor="let modulo of modulos" value="{{modulo.idModServRep}}">{{modulo.descripcion}}</option>
                    </select>
                </div>
                <div class="col-sm-2">
                    <label for="">*Prioridad</label>
                    <select class="form-control">
                        <option *ngFor="let prioridad of prioridades" value="{{prioridad.id}}">{{prioridad.descripcion}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <label for="">*Resumen del problema</label>
                    <textarea name="" id="" cols="30" rows="5" class="form-control"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-9">
                    <label for="">*Nombre de contacto</label>
                    <input class="form-control" type="text" value="{{contacto}}">
                </div>
                <div class="col-sm-3">
                    <label for="">*Teléfono de contacto</label>
                    <input type="tel" id="tel" class="form-control" value="{{telefonoContacto}}">
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <label for="">Notas</label>
                    <textarea name="" id="" cols="30" rows="5" class="form-control"></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 mt-3">
                    <label for="">Documentación del problema</label>
                    <div class="dropzone" appDnd (onFileDropped)="uploadFile($event)">
                        <input type="file" id="fileDropRef"  accept="image/*,.xls,.xlsx,.pdf" hidden #fileInput (change)="uploadFile($event)">
                        <img src="assets/upload-file.png" style="height: 20%;">
                        <h3 id="h3a">Arrastre los archivos aqui</h3>
                        <h3 id="h3b">o</h3>
                        <label for="fileDropRef" id="lbldrag">Busque el archivo</label>
                    </div>
                    <div class="files-list" *ngFor="let file of files;let i= index">
                        <p> {{ file.name }} </p>
                        <button class="delete-file" (click)="deleteAttachment(i)">
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </div>
                </div>
        
            </div>
            <div class="row mt-4 mb-4">
                <div class="col-sm-12">
                    <button (click)="Enviar()" class="btn" style="float: right;margin-bottom: 5%;font-weight: bold;"><i class="far fa-paper-plane"></i>&nbsp;Enviar</button>
                </div>
            </div>
        </div>


<!-- modal -->
<ng-template #mymodal5 let-modal5>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title"></h5>
        <button type="button" class="close" aria-label="Close" (click)="modal5.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>

<div class="modal-body">
    <div class="row">
        <div class="col-sm-12" id="logocontainer">
            <img src="assets/descarga.png" id="logo" alt="">
        </div>
        <div class="col-sm-12">
            <h4>SACK - Servicio de atención a clientes V.4.1.0.1</h4>
        </div>
        <div class="col-sm-12">
            <h6><b>Agua Soluciones</b></h6>
        </div>
        <div class="col-sm-12">
            <h6>Avenida Ayuntamiento No. 413 Int. 301 Colonia Los Cedros</h6>
        </div>
        <div class="col-sm-12">
            <h6>Aguascalientes, Ags., Mexico</h6>
        </div>

    </div>
</div>
<div class="modal-footer">
    <div class="col-sm-12 " style="text-align: center;">
        <h6>Copyright© SACK 2021.</h6>
    </div>
</div>
</ng-template>