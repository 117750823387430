import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from 'src/app/Model/usuario';
import { ApiserviceService } from 'src/app/Services/apiservice.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  usuario: Usuario;
  constructor(private router: Router, private apiservice: ApiserviceService, private modalService: NgbModal) { }
  closeResult = '';
  ngOnInit(): void {

  }
  CambiaContrasenia() {
    var contrasenia = (<HTMLInputElement>document.getElementById("pass")).value;
    var confirma = (<HTMLInputElement>document.getElementById("pass1")).value;
    if (contrasenia == confirma) 
    {
      var idCliente = sessionStorage.getItem('idCliente');
      this.apiservice.CambiaContrasenia(contrasenia, idCliente).subscribe((data) => {
        console.log(data);
        if (data != null) {
          alert("Contraseña actualizada.");
          this.modalService.dismissAll();
          var inputContrasenia=(<HTMLInputElement>document.getElementById("contra"));
          inputContrasenia.value="";
          inputContrasenia.focus();
        }

      });
    }
    else{
      alert("Las contraseñas no coinciden. verifique para continuar.");
      (<HTMLInputElement>document.getElementById("pass")).value="";
      (<HTMLInputElement>document.getElementById("pass1")).value="";
      (<HTMLInputElement>document.getElementById("pass")).focus();
    }
  }

  open(content) {
    var usuario = new Usuario();
    usuario.NoEstacion = document.getElementsByTagName("input")[0].value;
    usuario.Contrasenna = document.getElementsByTagName("input")[1].value;
    this.apiservice.login(usuario).subscribe((data) => {
      console.log(data);
      if (data != null) {
        this.usuario = data as Usuario;
        sessionStorage.setItem('idCliente', this.usuario.idCliente.toString());
        if (this.usuario.cambioContrasenna == 1) {
          this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title',size: 'sm', backdrop: 'static' }).result.then((result) => {
            this.closeResult = 'Closed with: ${result}';
          }, (reason) => {
            this.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
          });
        }
        else {
          sessionStorage.setItem('RazonSocial', this.usuario.RazonSocial);
          sessionStorage.setItem('NoEstacion', this.usuario.NoEstacion);
          sessionStorage.setItem('Correo', this.usuario.Correo);
          sessionStorage.setItem('Telefono', this.usuario.Telefono);
          sessionStorage.setItem('Contacto', this.usuario.Contacto);
          alert("Bienvenido " + this.usuario.RazonSocial);
          this.router.navigate(['inicio']);
        }
      }
      else {
        alert("Usuario o contraseña incorrectos");
        document.getElementsByTagName("input")[0].value = "";
        document.getElementsByTagName("input")[1].value = "";
      }
    });

  }
  openOlvido(Olvido) {
    this.modalService.open(Olvido, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = 'Closed with: ${result}';
    }, (reason) => {
      this.closeResult = 'Dismissed ${this.getDismissReason(reason)}';
    });


  }
  CrearPassword(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789*/-+@_!?¡¿%$&#';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }
  CambiarContrasenia() {
    var cliente;
    cliente = (<HTMLInputElement>document.getElementById('UsuarioOlvido')).value;
    var pass = this.CrearPassword(10);
    this.apiservice.CambiarContrasenia(cliente, pass).subscribe((data) => {
      if (data.response == 'No existe el usuario') {
        alert("Ha sucedido un error,inténtelo más tarde.");
      }
      else {
        alert('Se ha enviado un correo con su nueva contraseña');
      }

    }, (err) => {
      alert('Se ha enviado un correo con su nueva contraseña');
    });
    this.modalService.dismissAll();
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return 'with: ${reason}';
    }
  }
  Mostrar() {
    var contrasenia = document.getElementsByTagName("input")[1];
    if (contrasenia.type == "password") {
      contrasenia.type = "text";
    }
    else {
      contrasenia.type = "password";
    }
  }
  Mostrar1() {
    var contrasenia = document.getElementsByTagName("input")[2];
    if (contrasenia.type == "password") {
      contrasenia.type = "text";
    }
    else {
      contrasenia.type = "password";
    }
  }
  Mostrar2() {
    var contrasenia = document.getElementsByTagName("input")[3];
    if (contrasenia.type == "password") {
      contrasenia.type = "text";
    }
    else {
      contrasenia.type = "password";
    }
  }
}
