<script type="text/JavaScript">
    var pwd = document.getElementById('pwd');
var eye = document.getElementById('eye');

eye.addEventListener('click',togglePass);

function togglePass(){

   eye.classList.toggle('active');

   (pwd.type == 'password') ? pwd.type = 'text' : pwd.type = 'password';
}

// Form Validation

function checkStuff() {
  var email = document.form1.email;
  var password = document.form1.password;
  var msg = document.getElementById('msg');
  
  if (email.value == "") {
    msg.style.display = 'block';
    msg.innerHTML = "Please enter your email";
    email.focus();
    return false;
  } else {
    msg.innerHTML = "";
  }
  
   if (password.value == "") {
    msg.innerHTML = "Please enter your password";
    password.focus();
    return false;
  } else {
    msg.innerHTML = "";
  }
   var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!re.test(email.value)) {
    msg.innerHTML = "Please enter a valid email";
    email.focus();
    return false;
  } else {
    msg.innerHTML = "";
  }
}

// ParticlesJS

// ParticlesJS Config.
particlesJS("particles-js", {
  "particles": {
    "number": {
      "value": 60,
      "density": {
        "enable": true,
        "value_area": 800
      }
    },
    "color": {
      "value": "#ffffff"
    },
    "shape": {
      "type": "circle",
      "stroke": {
        "width": 0,
        "color": "#000000"
      },
      "polygon": {
        "nb_sides": 5
      },
      "image": {
        "src": "img/github.svg",
        "width": 100,
        "height": 100
      }
    },
    "opacity": {
      "value": 0.1,
      "random": false,
      "anim": {
        "enable": false,
        "speed": 1,
        "opacity_min": 0.1,
        "sync": false
      }
    },
    "size": {
      "value": 6,
      "random": false,
      "anim": {
        "enable": false,
        "speed": 40,
        "size_min": 0.1,
        "sync": false
      }
    },
    "line_linked": {
      "enable": true,
      "distance": 150,
      "color": "#ffffff",
      "opacity": 0.1,
      "width": 2
    },
    "move": {
      "enable": true,
      "speed": 1.5,
      "direction": "top",
      "random": false,
      "straight": false,
      "out_mode": "out",
      "bounce": false,
      "attract": {
        "enable": false,
        "rotateX": 600,
        "rotateY": 1200
      }
    }
  },
  "interactivity": {
    "detect_on": "canvas",
    "events": {
      "onhover": {
        "enable": false,
        "mode": "repulse"
      },
      "onclick": {
        "enable": false,
        "mode": "push"
      },
      "resize": true
    },
    "modes": {
      "grab": {
        "distance": 400,
        "line_linked": {
          "opacity": 1
        }
      },
      "bubble": {
        "distance": 400,
        "size": 40,
        "duration": 2,
        "opacity": 8,
        "speed": 3
      },
      "repulse": {
        "distance": 200,
        "duration": 0.4
      },
      "push": {
        "particles_nb": 4
      },
      "remove": {
        "particles_nb": 2
      }
    }
  },
  "retina_detect": true
});
    
</script>
<div class="logn pt-5">
    <div class="animated bounceInDown">
        <div class="container mt-5">
          <span class="error animated tada" id="msg"></span>
          <form name="form1" class="box" >
              <div class="row pt-4">
                  <div class="col-sm-12">
                      <img src="/assets/SACK_logo_ok.png" id="logo" alt="" class="w-75 text.center">
                  </div>
              </div>
              <br>
            <h5>Ingrese a su cuenta.</h5>
      
              <input type="text" class="form-control" placeholder="Usuario">
              
              <i class="typcn typcn-eye" id="eye"></i>
                  <input type="password" id="contra" class="form-control" (keydown.enter)="open(content)">
              <div class="row mb-5">
                  <div class="col-sm-12">
                      <label>
                          <input type="checkbox">
                          <span></span>
                          <small class="rmb">Recordarme?</small>
                        </label>
                  </div>
                  <div class="col-sm-12">
                      <label>
                          <small class="rmb" (click)="openOlvido(Olvido)">Olvido su contraseña?</small>
                      </label>
                      
                  </div>
              </div>
             
              <button (click)="open(content)"class="btn1 mt-4"><i class="fas fa-sign-in-alt"></i>&nbsp;Iniciar sesión</button> 
            </form>
      
        </div> 
             <div class="footer">
            
          </div>
      </div>
</div>



<!-- Modal -->
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Cambiar contraseña</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-12">
                <label for="">Nueva contraseña</label>
                <div class="input-group">
                    <input type="password" id="pass" class="form-control">
                    <span class="input-group-btn">
                         <button class="btn" (click)="Mostrar1()"><i class="far fa-eye"></i></button>
                    </span>
                </div>
            </div>
            <div class="col-sm-12">
                <label for="">Confirmar contraseña</label>
                <div class="input-group">
                    <input type="password" id="pass1" class="form-control">
                    <span class="input-group-btn">
                         <button class="btn" (click)="Mostrar2()"><i class="far fa-eye"></i></button>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn" (click)="CambiaContrasenia()">Aceptar</button>
    </div>
</ng-template>




<!-- Modal -->
<ng-template #Olvido let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Generar nueva contraseña</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-12">
                <b><label for=""><i class="far fa-user"></i>&nbsp;Usuario</label></b>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Usuario" id="UsuarioOlvido">
                    
                </div>
            </div>
            
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn" (click)="CambiarContrasenia()">Aceptar</button>
    </div>
</ng-template>