import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ElementRef, ViewChild } from "@angular/core";
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {

  
  constructor(private router:Router, private modalService: NgbModal) { }
  closeResult: string;


  ngOnInit(): void {
    this.ValidaSesion();
  }
  Redirige(path)
  {
    this.router.navigate([path]);
  }
  public LogOut()
  {
    sessionStorage.clear();
    this.router.navigate(['']);
  }
  public ValidaSesion()
  {
    if(sessionStorage.getItem('idCliente')==null)
    {
      this.router.navigate(['']);
    }
  }

  openAcercaDe(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size:'lg', centered: true, backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
