import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InicioComponent } from './Components/inicio/inicio.component';
import { LoginComponent } from './Components/login/login.component';
import { NuevoTicketComponent } from './Components/nuevo-ticket/nuevo-ticket.component';
import { TicketsactivosComponent } from './Components/ticketsactivos/ticketsactivos.component';
import { TicketsfinalizadosComponent } from './Components/ticketsfinalizados/ticketsfinalizados.component';

const routes: Routes = [
  {path:'nuevoticket',component:NuevoTicketComponent},
  {path:'',component:LoginComponent},
  {path:'ticketsactivos',component:TicketsactivosComponent},
  {path:'ticketsfinalizados',component:TicketsfinalizadosComponent},
  {path:'inicio',component:InicioComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
