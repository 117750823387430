import { HostListener, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFileUploaderComponent } from 'angular-file-uploader';
import { Prioridad } from 'src/app/Model/prioridad';
import { ApiserviceService } from 'src/app/Services/apiservice.service';


@Component({
  selector: 'app-nuevo-ticket',
  templateUrl: './nuevo-ticket.component.html',
  styleUrls: ['./nuevo-ticket.component.css']
})

export class NuevoTicketComponent implements OnInit {
  files: any = [];

  closeResult: string;
  constructor(private router: Router, private apiservice: ApiserviceService,private modalService: NgbModal) { }
  prioridades: any = [];
  modulos: any = [];
  telefonoContacto = sessionStorage.getItem('Telefono');
  contacto = sessionStorage.getItem('Contacto');
  ngOnInit(): void {
    this.ValidaSesion();
    this.apiservice.ObtenerPrioridad().subscribe((data) => {
      console.log(data);
      if (data != null) {
        this.prioridades = data;
      }
    });
    this.apiservice.ObtenerModulos().subscribe((data) => {
      console.log(data);
      if (data != null) {
        this.modulos = data;
      }
    });
  }
  uploadFile(event) {
    for (let index = 0; index < event.target.files.length; index++) {
      this.files.push(event.target.files[index]);
    }
  }
  deleteAttachment(index) {
    this.files.splice(index, 1)
  }
  Redirige(path) {
    this.router.navigate([path]);
  }
  VerificarNoTelefono(inputtxt) {
    var phoneno = /^\d{10}$/;
    if ((inputtxt.value.match(phoneno))) {
      return true;

    }
    else {
      alert("Número de teléfono inválido.");
      inputtxt.value = "";
      //inputtxt.focus();
      return false;
    }
  }
  Enviar() {
    var fd = new FormData();
    for (var x = 0; x < this.files.length; x++) {
      fd.append("fileToUpload[]", this.files[x]);
    }
    var modulo = document.getElementsByTagName("select")[0].value;
    var prioridad = document.getElementsByTagName("select")[1].value;
    var resumen = document.getElementsByTagName("textarea")[0].value;
    var telContacto = document.getElementsByTagName("input")[1].value;
    var contacto = document.getElementsByTagName("input")[0].value;
    var comentarios = document.getElementsByTagName("textarea")[1].value;
    var CreadoPor="Cliente";
    if (modulo.length > 0 && prioridad.length > 0 && resumen.length > 0 && telContacto.length > 0 && contacto.length > 0) {
      if (this.VerificarNoTelefono((<HTMLInputElement>document.getElementById("tel")))) {
        fd.append('idModServRep', modulo);
        fd.append('idCliente', sessionStorage.getItem('idCliente'));
        fd.append('prioridad', prioridad);
        fd.append('resumen', resumen);
        fd.append('telContacto', telContacto);
        fd.append('contacto', contacto);
        fd.append('comentarios', comentarios);
        fd.append('razonSocial', sessionStorage.getItem('RazonSocial'));
        fd.append('estacion', sessionStorage.getItem('NoEstacion'));
        fd.append('correo', sessionStorage.getItem('Correo'));
        fd.append('creadoPor',CreadoPor);
        var sel = document.getElementsByTagName("select")[0];
        var text = sel.options[sel.selectedIndex].text;
        fd.append('modulo', text);
        var sel1 = document.getElementsByTagName("select")[1];
        var text1 = sel1.options[sel1.selectedIndex].text;
        fd.append('prioridadDesc', text1)
        this.apiservice.EnviarTicket(fd).subscribe((data) => {
          alert("Ticket enviado correctamente");
          this.router.navigate(['inicio']);
          alert(data[0]);
          if (data != null) {
            console.log(data);
            alert("Ticket enviado correctamente");
            this.router.navigate(['inicio']);
          }
          else {
            alert(data);
          }
          alert("Ticket enviado correctamente");
          this.router.navigate(['inicio']);
        }, (err) => {
          alert("Ticket enviado correctamente");
          this.router.navigate(['inicio']);
        });
      }
      else{
        alert("Número de teléfono no válido.");
        var inputTel=(<HTMLInputElement>document.getElementById("tel"));
        inputTel.value="";
        inputTel.focus();
      }
    }
    else {
      alert("Complete todos los campos marcados con * para continuar.");
    }

  }
  public LogOut() {
    sessionStorage.clear();
    this.router.navigate(['']);
  }
  public ValidaSesion() {
    if (sessionStorage.getItem('idCliente') == null) {
      this.router.navigate(['']);
    }
  }

  openAcercaDe(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size:'lg', centered: true, backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
